import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppService } from 'src/app/shared/app.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  EmailID: string = '';
  labelsObj = {
    'Home': 'Home',
    'Disclaimer': 'Disclaimer',
    'Careers': 'Careers',
    'Hyderabad Office': 'Hyderabad Office',
    'Get In Touch': 'Get In Touch',
    'FAQs': 'FAQs',
    'Stories of Science': 'Stories of Science',
    'Newsletter': 'Newsletter',
    'Copyright': 'Copyright',
    'All rights reserved': 'All rights reserved',
    'Quick Links': 'Quick Links',
    'Privacy Policy': 'Privacy Policy',
    'IP Policy': 'IP Policy',
    'India Alliance': 'India Alliance',
    'Sign Up For Newsletter': 'Sign Up For Newsletter',
    "IAContent": `DBT/Wellcome Trust India Alliance (India Alliance) is an independent, dynamic public charity that 
    funds research in health and biomedical sciences in India. India Alliance invests in transformative 
    ideas and supportive research ecosystems to advance discovery and innovation to improve health 
    and well-being. India Alliance encourages diversity, inclusivity, and transparency in science 
    and works to facilitate engagement of science with society.`,
    'iaContext': `DBT/Wellcome Trust India Alliance, Nishant House, 8-2-351/N/1, 2nd
    floor, Road No. 2, Venkateshwara Hills, Banjara Hills, Hyderabad - 500034`
  }
  currentLabelsObj: any = {}
  constructor(private _appService: AppService, private nzmessage: NzMessageService, private _NzMessageService: NzMessageService) {
    this._appService.onChangeLanguageEmitter.subscribe((data: any) => {
      if(data!='en'){
        this.onTranslate()
      }else{
        this.currentLabelsObj = { ...this.labelsObj }
      }
    })
  }
  ngOnInit(): void {
    this.currentLabelsObj = { ...this.labelsObj }
  }
  onSubrForNewsLetter() {
    try {
      this._appService
        .postMethod("Subscribe_Newsletter_Email", { EmailID: this.EmailID })
        .subscribe(
          (resp) => {
            if (resp.success) {
              this.nzmessage.success('Subscribed Successfully')
            } else {
            }
          },
          (resp: any) => {
            this._NzMessageService.error(resp.error.extras.msg);
          }
        );
    }
    catch (e) { }
  }
  onTranslate() {
    this.currentLabelsObj = this._appService.onTranslate(this.labelsObj, this.currentLabelsObj)
  }
}
